@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=DM+Serif+Display&display=swap");

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  min-height: 100%;
  background-color: #fff;
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  line-height: 130%;
}

.cascade {
  display: flex;
  flex-direction: column;
}

.centre {
  text-align: center;
  align-items: center;
}

.sidebar {
  display: grid;
  min-height: 100vh;
  color: #ffffff;
  grid-template-rows: min-content;
  align-items: center;
  background-color: #2c4a43;
  padding: 24px 16px;
}

.sidebarHeader {
  font-size: 24px;
  font-family: "DM Sans", sans-serif;
  font-weight: normal;
  margin: 0;
}

.sidebarNavButton {
  border: #d8dcd4 solid 1px;
  padding: 16px;
  border-radius: 15px;
  display: flex;
  width: 100%;
  margin-bottom: 10px;
}

.activeNavButton,
.sidebarNavButton:hover {
  background-color: #d8dcd4;
  color: white;
}

.tabNavButton {
  border: #d8dcd4 solid 1px;
  background-color: #ebeee7;
  padding: 16px;
  border-radius: 15px;
  display: flex;
  width: 100%;
  margin-bottom: 10px;
}

.activetabNavButton {
  background-color: #ffe768;
  color: #2c4a43;
}

.tabNavButton:hover {
  background-color: #6ce4bf;
  color: #2c4a43;
}

.sidebarFooter {
  align-self: self-end;
}

.sidebarFooterText {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 24px;
}

.sidebarFooter h2 {
  width: 60%;
  margin: 0;
}

.sidebarFooter h2 span:nth-of-type(1) {
  font-weight: normal;
  font-size: 12px;
  line-height: 110%;
}

.sidebarFooter h2 span:nth-of-type(2) {
  font-weight: normal;
  font-size: 18px;
  line-height: 110%;
}

.dash {
  min-height: 100vh;
  display: grid;
  grid-template-columns: 264px 1fr;
}

.screen {
  height: 100vh;
  overflow: auto;
  position: relative;
}

.screen > * > * {
  padding: 0 24px 24px;
}

.loadingOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #6ce4bf;
  opacity: 0.8;
  align-items: center;
  justify-content: center;
  display: flex;
  z-index: 1;
}

.managePatients {
  display: grid;
  height: 100%;
  grid-template-rows: min-content 1fr;
}

.emailInput,
.viewing {
  padding-top: 24px;
  display: flex;
  align-items: center;
  background-color: #f5f8f2;
}

.emailInput input {
  margin: 0px 24px 0px 40px;
  min-width: 300px;
}

.stockLevelsLabel,
.emailInputLabel label {
  font-size: 24px;
}

.stockDataItemContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.stockQuantityInput {
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  width: 10%;
}

.personaInquiryIdInput {
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  width: 10%;
}

.stockItemName {
  width: 10%;
  text-align: center;
}

.patientCol {
  min-width: 10%;
  text-align: center;
}

.stockItemsButtonContainer {
}

.emailInputLabel span {
  font-size: 16px;
  color: #2c4a43;
}

.viewingText span {
  margin: 0px 24px 0px 8px;
}

.patientList {
  overflow: scroll;
}

.patientList h3 {
  margin-bottom: 0;
  font-family: "DM Serif Display", sans-serif;
  line-height: 110%;
  font-weight: normal;
  font-size: 32px;
}

.patientList .subtext {
  font-size: 20px;
  line-height: 110%;
  color: #2c4a43;
}

.patientList h4 {
  margin-bottom: 4px;
  font-size: 20px;
}

.patientList button {
  display: flex;
  font-size: 18px;
  padding: 5px;
  align-items: flex-start;
  text-align: left;
}

.patientList ul {
  list-style-type: none;
  padding-left: 0;
  margin-top: 0;
}

.patientList button::before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  border: 2px solid #d8dcd4;
  border-radius: 50%;
  margin-right: 7px;
  box-sizing: border-box;
  flex-shrink: 0;
  margin-top: 3px;
}

.patientList button:hover::before {
  border: 4px solid #3898ec;
}

.patientListNoResultsText {
  display: block;
  margin-top: 40px;
}

.patientData {
}

.cta {
  background-color: #3898ec;
  padding: 10px 24px;
  font-size: 16px;
  border-radius: 100px;
  border: none;
  color: white;
}

.cta:disabled {
  background-color: #d8dcd4;
}

.cancelCta {
  background-color: #d8dcd4;
  color: #2c4a43;
}

.login__btn {
  font-size: 18px;
  width: 100%;
}

.patientNoteContainer {
  display: grid;
  grid-template-rows: 1fr min-content;
  grid-gap: 24px;
  height: 100%;
}

.noteItemContainer {
  margin-bottom: 16px;
}

.noteItemContainer:last-of-type {
  margin-bottom: 0;
}

.patientNoteData {
  border: #dbdada 1px solid;
  overflow: scroll;
  background-color: #f7fbfa;
  padding: 8px;
}

.notesFilter {
  margin-bottom: 20px;
}

.tagContainer {
  width: 50%;
}

.tagsTitleText {
  margin-top: 20px;
}

.newNoteButton {
  margin-top: 10px;
  width: 50%;
}

.newNoteTextInput {
}

.noteAttribute {
  font-size: 12px;
  line-height: 120%;
  color: #2c4a43;
}

.note {
  font-size: 15px;
}

.cancelUserButton {
  background-color: #f0663b;
}
.trackOrderButton {
  background-color: #99a7eb;
}

button,
input[type="submit"],
input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
}

.toggleButton {
  color: #2c4a43;
  font-weight: 400;
  font-size: 14px;
  text-transform: uppercase;
  width: 40px;
  height: 40px;
  padding: 0px;
  border-radius: 50%;
  background-color: #ebeee7;
  justify-content: center;
  margin-left: 10px;
}

.toggleButton:disabled {
  background-color: #6ce4bf;
}

.toggleButton:last-of-type {
  margin-right: 10px;
}

.weightContainer {
}

.weightEditor {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.activityPanel {
  max-width: 400px;
}

.activityHeader {
  margin-bottom: 40px;
}

.activityHeader h3 {
  font-family: "DM Serif Display", sans-serif;
  font-weight: normal;
  font-size: 32px;
  line-height: 110%;
  margin: 0px;
}

.activityHeader > span {
  font-size: 20px;
  line-height: 110%;
  color: #2c4a43;
}

.activityPanel button {
  text-align: left;
  display: flex;
  justify-content: space-between;
  padding: 8px 0px;
}

.activityPanel button::after {
  content: "";
  display: block;
  width: 34px;
  height: 17px;
  background-image: url("../images/chevron-dark.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.activityPanel button.active::after {
  background-image: url("../images/eyecon.png");
}

.activityScreen {
  background: #ecf4f4;
  box-shadow: 0px 2.02062px 7.07216px rgba(44, 74, 67, 0.1);
  border-radius: 8px;
  padding: 20px 22px;
  margin-left: 100px;
  min-height: 422px;
  max-height: 550px;
  overflow: auto;
}

.activityPanel,
.activityScreen {
  flex: 1;
}

.activities {
  /* display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: min-content;
  grid-gap: 10px;
  margin-top: 48px; */
  display: flex;
  flex-direction: row;
  margin-top: 48px;
}

.patientSubscription {
  display: grid;
  height: 100%;
  grid-template-rows: 1fr min-content;
}

.patientSubscriptionLinks {
}

.magicLinkButton {
  margin-bottom: 16px;
}

.magicLinkButton:last-of-type {
  margin-bottom: 0;
}

.reportCard {
  border-radius: 12px;
  padding: 24px;
  margin: 24px;
  flex: 1;
  min-width: 40%;
  border-color: #2c4a43;
  border-width: 1;
  background-color: #d8dcd4;
  color: #2c4a43;
}

.reportCard:first-of-type {
  min-width: 60%;
}

.reportTools {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3898ec;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 0.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
